import React from 'react'

function ContactUs() {
    return (
        <div style={{marginTop:200}}>
            ContactUs
        </div>
    )
}

export default ContactUs