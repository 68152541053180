import React from "react";
import { NavLink } from "react-router-dom";

import {CardProject} from "../Utils/Data";

function Blog() {
  return (
    <div style={{ marginTop: 200 }}>
      <>
        <section class="w-full py-5 md:py-5">
          <div class="w-full theme-container mx-auto flex items-center flex-col text-center">
            <h1
              class="max-w-[813px] font-semibold text-4xl sm:text-40 tracking-tight text-app-dark text-center mt-[18px]"
              data-aos="fade-up"
              data-aos-delay="50"
            >
              المشاريع المنفذة سابقاً
            </h1>
            <div class="grid gird-cols-4 md:grid-cols-8 xl:grid-cols-12 gap-[30px] mt-8 md:mt-[70px]">
              {CardProject.map((item, i) => (
                <div data-aos="fade-up" data-aos-delay="100" class="col-span-4">
                  <NavLink to="/Project1">
                    <div class="p-5 md:p-12 relative overflow-hidden rounded-[20px] border-[1.2px] border-purple/10 h2_features_card_bgcolor cursor-pointer group hover:border-purple transition-all duration-300">
                      <div class="relative z-10">
                        <img
                          src={item.img}
                          alt="img"
                          class="my-5 max-w-[310px] w-full object-cover max-h-[300px] overflow-hidden rounded-lg group-hover:scale-105 transition-all duration-300"
                        />
                        <h1 class="text-20 mt-5  mb-5 text-blue-seo font-semibold pt-1 transition-all duration-300 ">
                         {item.title}
                        </h1>
                      </div>
                    </div>
                  </NavLink>
                </div>
              ))}

              {/* <div data-aos="fade-up" data-aos-delay="100" class="col-span-4">
                <NavLink to="/Project1">
                  <div class="p-5 md:p-12 relative overflow-hidden rounded-[20px] border-[1.2px] border-purple/10 h2_features_card_bgcolor cursor-pointer group hover:border-purple transition-all duration-300">
                    <div class="relative z-10">
                      <img
                        src="./assets/images/logos/logo1.png"
                        alt="img"
                        class="my-5 max-w-[310px] w-full object-cover max-h-[300px] overflow-hidden rounded-lg group-hover:scale-105 transition-all duration-300"
                      />
                      <h1 class="text-20 mt-5  mb-5 text-blue-seo font-semibold pt-1 transition-all duration-300 ">
                        تعزيز القدرات المؤسسية للجمعيات الشبابية في القطاع غير
                        الربحي​
                      </h1>
                    </div>
                  </div>
                </NavLink>
              </div> */}

              {/* <div data-aos="fade-up" data-aos-delay="100" class="col-span-4">
                <NavLink to="/Project2">
                  <div class="p-5 md:p-12 relative overflow-hidden rounded-[20px] border-[1.2px] border-purple/10 h2_features_card_bgcolor cursor-pointer group hover:border-purple transition-all duration-300">
                    <div class="relative z-10">
                      <img
                        src="./assets/images/logos/logo2.png"
                        alt="img"
                        class="my-5 max-w-[310px] w-full object-cover max-h-[300px] overflow-hidden rounded-lg group-hover:scale-105 transition-all duration-300"
                      />
                      <h1 class="text-20 mt-5  mb-5 text-blue-seo font-semibold pt-1 transition-all duration-300 ">
                        برنامج تسريع ابتكار الجمعيات الأهلية في القطاع غير
                        الربحي ​
                      </h1>
                    </div>
                  </div>
                </NavLink>
              </div> */}

              {/* <div data-aos="fade-up" data-aos-delay="100" class="col-span-4">
                <NavLink to="/Project3">
                  <div class="p-5 md:p-12 relative overflow-hidden rounded-[20px] border-[1.2px] border-purple/10 h2_features_card_bgcolor cursor-pointer group hover:border-purple transition-all duration-300">
                    <div class="relative z-10">
                      <img
                        src="./assets/images/logos/logo3.png"
                        alt="img"
                        class="my-5 max-w-[310px] w-full object-cover max-h-[300px] overflow-hidden rounded-lg group-hover:scale-105 transition-all duration-300"
                      />
                      <h1 class="text-20 mt-5  mb-5 text-blue-seo font-semibold pt-1 transition-all duration-300 ">
                        مسرعة الأعمال التنموية للجمعيات الأهلية بمنطقة الرياض​
                      </h1>
                    </div>
                  </div>
                </NavLink>
              </div> */}

              {/* <div data-aos="fade-up" data-aos-delay="100" class="col-span-4">
                <NavLink to="/Project4">
                  <div class="p-5 md:p-12 relative overflow-hidden rounded-[20px] border-[1.2px] border-purple/10 h2_features_card_bgcolor cursor-pointer group hover:border-purple transition-all duration-300">
                    <div class="relative z-10">
                      <img
                        src="./assets/images/logos/logo4.png"
                        alt="img"
                        class="my-5 max-w-[310px] w-full object-cover max-h-[300px] overflow-hidden rounded-lg group-hover:scale-105 transition-all duration-300"
                      />
                      <h1 class="text-20 mt-5  mb-5 text-blue-seo font-semibold pt-1 transition-all duration-300 ">
                        تأسيس وبناء قدرات المنظمات
                        <br />
                        غير الربحية في مجالات متعددة​
                      </h1>
                    </div>
                  </div>
                </NavLink>
              </div> */}

              {/* <div data-aos="fade-up" data-aos-delay="100" class="col-span-4">
                <NavLink to="/Project5">
                  <div class="p-5 md:p-12 relative overflow-hidden rounded-[20px] border-[1.2px] border-purple/10 h2_features_card_bgcolor cursor-pointer group hover:border-purple transition-all duration-300">
                    <div class="relative z-10">
                      <img
                        src="./assets/images/logos/logo5.png"
                        alt="img"
                        class="my-5 max-w-[310px] w-full object-cover max-h-[300px] overflow-hidden rounded-lg group-hover:scale-105 transition-all duration-300"
                      />
                      <h1 class="text-20 mt-5  mb-5 text-blue-seo font-semibold pt-1 transition-all duration-300 ">
                        حاضنة جدارة للأعمال الاجتماعية للجمعيات الناشئة
                        والمتعثرة​
                      </h1>
                    </div>
                  </div>
                </NavLink>
              </div> */}

              {/* <div data-aos="fade-up" data-aos-delay="100" class="col-span-4">
                <NavLink to="/Project6">
                  <div class="p-5 md:p-12 relative overflow-hidden rounded-[20px] border-[1.2px] border-purple/10 h2_features_card_bgcolor cursor-pointer group hover:border-purple transition-all duration-300">
                    <div class="relative z-10">
                      <img
                        src="./assets/images/logos/logo6.png"
                        alt="img"
                        class="my-5 max-w-[310px] w-full object-cover max-h-[300px] overflow-hidden rounded-lg group-hover:scale-105 transition-all duration-300"
                      />
                      <h1 class="text-20 mt-5  mb-5 text-blue-seo font-semibold pt-1 transition-all duration-300 ">
                        تقييم وبناء قدرات الجمعيات التابعة للهيئة العامة
                        للأوقاف​
                      </h1>
                    </div>
                  </div>
                </NavLink>
              </div> */}
            </div>
          </div>
        </section>
      </>
    </div>
  );
}

export default Blog;
