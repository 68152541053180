import React from 'react'

function BusinessCenter() {
    return (
        <div style={{marginTop:200}}>
            BusinessCenter
        </div>
    )
}

export default BusinessCenter