import React from "react";
import { NavLink } from "react-router-dom";

function Footer() {

  return (
    <footer class="pt-20 relative overflow-hidden">
      <div class="theme-container mx-auto mb-20">
        <div class="col-span-3">
          <img
            src="./assets/images/home-two/logo.png"
            width="30%"
            alt="logo"
          />
        </div>
      </div>
      <div class="absolute -right-[268px] top-[132px]">
        <img
          src="./assets/images/home-two/footer-shape.png"
          alt="footer"
        />
      </div>
      <div class="w-full h-[80px] md:h-[65px] bg-[#323239] relative z-10">
        <div class="theme-container mx-auto h-full">
          <div
            class="w-full h-full flex flex-col sm:flex-row justify-between items-center"
          >
            <span class="text-white/50 pt-3 md:pt-0"
            >© جميع الحقوق محفوظة، شركة رنين
            </span>
            <ul class="flex gap-5 items-center">
              <li class="text-foot">
                <a href="#">رنين تك</a>
              </li>
              <li class="text-foot">
                <a href="#">منصة جزيل</a>
              </li>
              <li class="text-foot">
                <a href="#">حاضنة جدارة</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
