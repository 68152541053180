import React from "react";

function Project6() {
  return (
    <div style={{ marginTop: 200 }}>
      <>
      <section class="w-full">
                <div class="w-full theme-container mx-auto flex items-center flex-col">
           
                  <img
                            src="../assets/images/imgs/img6.png"
                            alt="img"
                            class=""
                          />
           
                </div>
              </section>
      </>
    </div>
  );
}

export default Project6;
