

export const Question = [
  {
    name: "faq-1",
    title: "من رنين ؟",
    content: `    نحن شركة استشارية مختصة في القطاع غير الربحي، نؤمن
                                                              بأهمية القطاع غير الربحي في تحقيق التنمية المستدامة
                                                              ودعم المجتمعات ونسعى من خلال خدماتنا إلى تعزيز قدرات
                                                              الجمعيات والمنظمات غير الربحية لتحقيق أهدافها بنجاح
                                                              وفعالية وتحقيق مستهدفات رؤية المملكة العربية
                                                              السعودية 2030م.`,
  },
  {
    name: "faq-2",
    title: "أين رنين ؟",
    content: `نحن في قلب المملكة العربية السعودية بمدينة الرياض،
                                                              ونقدم خدماتنا لجميع المنظمات عبر المملكة، مستفيدين
                                                              من موقعنا المركزي لتمكيننا من التواصل الفعّال
                                                              والوصول إلى عملائنا بشكل أفضل.`,
  },
  {
    name: "faq-3",
    title: ` ما هي خبرة الشركة في العمل مع منظمات القطاع غير
                                                                  الربحي ؟`,
    content: `تمتلك شركة رنين خبرة واسعة في العمل مع منظمات القطاع
                                                              غير الربحي، حيث تعاونت مع العديد من الجمعيات
                                                              والمؤسسات والجهات الحكومية لتحقيق أهدافها التنموية،
                                                              نجحنا في تنفيذ العديد من المشاريع والمبادرات التي
                                                              ساهمت في تحقيق الاستدامة التنموية`,
  },
  {
    name: "faq-4",
    title: `ما هو الهدف الرئيسي لشركة رنين ؟`,
    content: `الهدف الرئيسي لشركة رنين هو تعزيز قدرات الجمعيات
                                                              والمنظمات غير الربحية من خلال تقديم خدمات استشارية
                                                              متخصصة ومشاريع شاملة تساهم في تحقيق التنمية
                                                              المستدامة وتحسين جودة الحياة`,
  },
  {
    name: "faq-5",
    title: `كيف يمكن للمنظمات التواصل مع شركة رنين ؟`,
    content: `يمكن للمنظمات التواصل مع شركة رنين عبر البريد
                                                              الإلكتروني، الهاتف، أو من خلال زيارة مقرنا في مدينة
                                                              الرياض. كما يمكنهم زيارة موقعنا الإلكتروني للحصول
                                                              على مزيد من المعلومات وملء استمارة التواصل`,
  },
  {
    name: "faq-6",
    title: `ما هي الفئات المستهدفة بخدمات شركة رنين ؟`,
    content: `    تستهدف شركة رنين جميع الجمعيات والمنظمات غير الربحية
                                                              والجهات الحكومية والشركات الخاصة بغض النظر عن حجمها
                                                              أو مجال عملها، نحن نؤمن بأن كل منظمة تستحق الدعم
                                                              لتحقيق أهدافها والمساهمة في تنمية المجتمع`,
  },
  {
    name: "faq-7",
    title: ` هل تقدم شركة رنين خدمات مخصصة لجمعيات ناشئة ؟`,
    content: `نعم، تقدم شركة رنين خدمات مخصصة للجمعيات الناشئة
                                                              تشمل بناء القدرات، تطوير البرامج، وإدارة الموارد،
                                                              الحوكمة، الاستدامة المالية بهدف مساعدتها على النمو
                                                              والاستدامة`,
  },
  {
    name: "faq-8",
    title: `هل تتعاون شركة رنين مع جهات دولية ؟`,
    content: `                                                            نعم، تتعاون شركة رنين مع العديد من الجهات الدولية
                                                              لتبني أفضل الممارسات العالمية في القطاع غير الربحي
                                                              وتقديم حلول مبتكرة تناسب السياق المحلي`,
  },
];



export const CardProject = [
  {
   
    title: `تعزيز القدرات المؤسسية للجمعيات الشبابية في القطاع غير الربحي​`,
     img:"../assets/images/logos/logo1.png"
  },
  {
   
    title: `برنامج تسريع ابتكار الجمعيات الأهلية في القطاع غير الربحي ​`,
      img:"../assets/images/logos/logo2.png"
  },
  {
   
    title: `مسرعة الأعمال التنموية للجمعيات الأهلية بمنطقة الرياض​`,
    img:"../assets/images/logos/logo3.png"
  },
  {
   
    title: `
    تأسيس وبناء قدرات المنظمات
    غير الربحية في مجالات متعددة​
    `,
  img:"../assets/images/logos/logo4.png"
  },
  {
   
    title: `حاضنة جدارة للأعمال الاجتماعية للجمعيات الناشئة والمتعثرة​`,
      img:"../assets/images/logos/logo5.png"
  },
  {
   
    title: `تقييم وبناء قدرات الجمعيات التابعة للهيئة العامة للأوقاف​`,
     img:"../assets/images/logos/logo6.png"
  },

];

export const Teams = [
  {
    name: "أ. غازي العليلي",
    title: ``,
    content: ` 
     الدرجة العلمية: بكالوريوس في إدارة الأعمال ​
     مدة الخبرة العملية: 12 سنة خبرة عملية.  ​
  
     مجالات العمل: ​
  
  الإدارة التنفيذية ​
  
  بناء الخطط الاستراتيجية​
  
  الاستشارات والتدريب ​
  
  
  أبزر الشهادات المهنية: ​
  
  الإدارة التنفيذية ​
  
  بناء الخطط الاستراتيجية​
  
  أخصائي الحوكمة​
  
  التخطيط والتنفيذ التشغيلي​
  
  مبادئ التصميم الجرافيكي​
  
  تصميم الحقائب التدريبية​
  
  
  مستشار التطوير المؤسسي للمنظمات غير الربحية، عمل في العديد من المشاريع الاستشارية وتطوير البيئات المؤسسية لدى الجهات الحكومية والقطاع غير الربحي والقطاع الخاص.​​
  
      `,

   img:"../assets/images/Teams/imgm.png"
  },
  {
    name: "د. محمد قائد​",
    title: ``,
    content: `
      الدرجة العلمية: دكتوراة  في تنمية قدرات المنظمات غير الربحية ​
      مدة الخبرة العملية: 11 سنة خبرة عملية.  ​
      مجالات العمل: ​

إدارة الجودة ​

مدرب معتمد ​

مستشار ​

أبزر الشهادات العلمية: ​

دبلوم عالي التنمية الدولية والنوع الاجتماعي ​

ماجستير إدارة الأعمال​


من خبراته الأساسية: ممارس معتمد في المسؤولية الاجتماعية للشركات مركز الاستدامة والتميز، لندن. موجه معتمد لنظام إدارة الجودة في المنظمات المجتمعية وكالة تقييم المؤسسات الخيرية، لندن.مدرب ومكيف محتوى معتمد لبرنامج بيزنس إيدج بمؤسسة التمويل الدولية. تطبيق المعايير الدولية المختلفة.​
​

      `,
   img:"../assets/images/Teams/imgm.png"

  },
  {
    name: "أ. سلمان سحاري​",
    title: ``,
    content: `
    الدرجة العلمية: ماجستير في الإدارة المالية  ​
    مدة الخبرة العملية: 8 سنوات خبرة عملية.  ​
    مجالات العمل: ​

الإدارة المالية  ​

مستشار مالي​

إدارة المشاريع التنموية ​
تصميم خطط العمل​

مدرب ​

​
أبزر الشهادات العلمية والمهنية: ​

بكالوريوس في الإعلام​

PMD​

FMD​

يملك الخبرة في تطوير المنتجات المتخصصة في القطاع غير الربحي. كما وقد قام بالعديد من التصاميم وإدارة المشاريع التنموية. إضافةً إلى تصممي الحقائب التدريبية الموجهة للقطاع غير الربحي. ويملك الخبرة الواسعة في تصميم خطط الأعمال، والموازنات المالية، وتقارير الرقابة، والمتابعة. كما وقد قدم العديد من الاستشارات. ​
​
    `,
   img:"../assets/images/Teams/imgm.png"

  },
  {
    name: "د.عبدالله الشريمي​",
    title: ``,
    content: ` 
    الدرجة العلمية: دكتوراة إدارة اعمال ​
    مدة الخبرة العملية: 15 سنة خبرة عملية.  ​
    مجالات العمل: ​

مستشار في تطوير الأعمال​

منسق مشاريع  ​

الاستشارات والتدريب​


أبزر الشهادات العلمية: 

ماجستير إدارة أعمال 

بكالوريوس​

يملك مهارات: استشارية وتطوير الأعمال، تدريبية، قيادية، والتواصل. عمل مستشار في البناء المؤسسي بمسرّعة الأعمال التنموية بمؤسسة سليمان الراجحي الخيرية، وكان أستاذ مساعد في إدارة الأعمال وعميد كلية العلوم الإدارية والإنسانية، عمل في تنسيق المشروع التكاملي لحماية النازحين، وكان عميد معهد التدريب التقني الصناعي​

    `,
   img:"../assets/images/Teams/abdalah.jpg"

  },

  {
    name: "م. سلطان العليان ​",
    title: ``,
    content: `
    الدرجة العلمية: بكالوريوس تقنية شبكات ​

وأمن حاسوب ​

مدة الخبرة العملية: 10 سنوات خبرة عملية.  ​

مجالات العمل: ​

إدارة المشاريع ​

الإدارة التقنية ​

الإدارة التشغيلية ​

إدارة الفعاليات​


أبزر الشهادات المهنية: ​

مؤهل P3.express​

CCAN​

CCNP​

MCSA​

MCSE​

​

قد كان قائد فريق لمشروع تقنيات جدة المشغلة في وزارة التعليم ومدينة جدة، رابغ، وخريص. يملك الخبرة الواسعة بمركز البيانات (أجهزة الشبكة – من موزعات والموجهات – وأنظمة الحماية الفايروول – والخوادم السحابية) حيث قد أشرف على مركز البيانات  وقام بإدارتها وبرمجتها.​
    `,
   img:"../assets/images/Teams/suldan.jpg"

  },

  {
    name: "م. جابر سحاري​",
    title: ``,
    content: `
    الدرجة العلمية: ماجستير في الإدارة المالية ​
    مدة الخبرة العملية: 5 سنوات خبرة عملية.  ​
    مجالات العمل: ​

بناء التميز المؤسسي​

مستشار أعمال ​

مقدم ورش عمل​

​
الإدارة التنفيذية​

مجلس الإدارة ​

مطور أعمال​

أبزر الشهادات العلمية: ​

بكالوريوس في الهندسة الكيميائية. ​

مهتم بتأسيس الكيانات الاستثمارية وبناء عملياتها الرئيسية لتحقيق أفضل النتائج على دراسات وأبحاث السوق. من مهاراته التخطيط الاستراتيجي، بناء الموازنات المالية، البناء المؤسسي، اتخاذ القرار، إعداد المقترحات ودراسة الجدوى، تحليل وبناء ملفات المنافسات، تحليل وتقييم الربحية، مدقق خارجي معتمد على نظام آيزوا 9001-2015 من منظمة اريكا مستشار جودة معتمد على نظام المنظمة الموثوقة. ​

    
    `,
   img:"../assets/images/Teams/imgm.png"

  },

  {
    name: "أ. زكريا الفضلي​",
    title: ``,
    content: `
     الدرجة العلمية: بكالوريوس في برمجة الحاسوب​

    مدة الخبرة العملية: 8 سنوات خبرة عملية. 
    مجالات العمل: 

مصمم جرافيك​

مطور مواقع إلكترونية ​

​

​
أبزر الدورات التدريبية: ​

Digital Marketing with Google​

Right way for Rights and Development ICDL​

A+​

يملك خبرة أكثر من 8 أعوام في المجال التقني وتطوير المشاريع التقنية. مبرمج ومطوّر مواقع ويب، وتطبيقات الهاتف الذكي، إضافةً إلى مصمم وموّر واجهات. وقد شارك وساهم في هكثونات بعدد من المشاريع التقنية المالية. يملك خبرة واسعة في تصميم الجرافيك والتعامل مع برامج الادوبي. ولديه الخبرة في تحليل المشاريع وتحليل قواعد البيانات، إضافةً إلى الخبرة في مجال UX/UI.​
    `,
   img:"../assets/images/Teams/imgm.png"

  },

  {
    name: "أ. رشاد أحمد ​",
    title: ``,
    content: `
    الدرجة العلمية: ماجستير في أصول التربية ​
    مدة الخبرة العملية: 25 سنة خبرة عملية.  ​
    مجالات العمل: ​

مدرس ومدرب معتمد​

مستشار في العمليات الرئيسية​

إدارة الجودة ​

أبزر الشهادات العلمية: ​

بكالوريوس في الدراسات الإسلامية ​

دبلوم متوسط في اللغة الإنجليزية ​

دبلوم الموارد البشرية ​

دبلوم التسويق المعتمد​

تصميم وتطوير نظام إدارة الجودة المتوافق مع متطلبات المواصفة العالمية تقديم استشارات في تطوير الأنظمة واللوائح الإدارية لما يزيد عن 20 منظمة مجتمع مدني، خبرة في إعداد المخططات الانسيابية للعمليات وصياغة سياسات وإجراءات العمل وتقديم الاستشارة المتخصصة في تطويرها، تقديم التدريب لـ 90 منظمة مجتمع مدني في مجال الجودة والبناء المؤسسي​

    `,
   img:"../assets/images/Teams/imgm.png"

  },

  {
    name: "أ. هند العتيبي​",
    title: ``,
    content: `
     الدرجة العلمية: بكالوريوس في إدارة الأعمال ​
     مدة الخبرة العملية: سنة ونصف خبرة عملية.  ​
     مجالات العمل: ​

إدارة المشاريع ​

تحليل الأعمال ​

بناء المخرجات ​

​
تطوير الأعمال ​

بناء قدرات ​

إدارة الفعاليات​

​
أبزر الدورات التدريبية: ​

برنامج مسك طريق المستقبل للإعداد الوظيفي​

دورة الثقافة العمالية ​

دورة تحضير لامتحان – CAMP مساعد مدير​

تملك مهارات إدارية وفنية في إعداد التقارير المتخصصة والمستندات الفنية المتعلقة بالمشاريع، متابعة إجراءات تنفيذ المشروع وتوثيق الإنجازات بحسب المخطط الزمني المحدد، واستخدام حزمة برامج Microsoft office ، لديها مهارات عالية في التواصل الفعال مع الشخصيات والتفاعل مع الفرق والجهات المعنية, وقدرة على وضع خطط زمنية لتنفيذ المشروع وتوزيع المهام على الفريق، وتقديم الدعم اللازم. ​

     `,
   img:"../assets/images/Teams/Hend.png"

  },
  
  {
    name: "أ. فاطمة المروعي​",
    title: ``,
    content: `
    الدرجة العلمية: دبلوم  لغة انجليزية ​
    مدة الخبرة العملية: 7 سنوات خبرة عملية.  ​
    مجالات العمل: ​

بناء الخطط التنموية والاستراتيجية والتشغيلية​

إعداد الخطابات والتقارير​

مساعد مستشار تطوير أعمال ​

​

​
أبزر الشهادات العلمية والمهنية: ​

PMD​

PMP​

TOT  ​

CCSC​

 تأهيل القيادات الشابة ​

​

​

​
تمتلك الخبرة والقدرة في إيجاد البناء المؤسسي للقطاع غير الربحي، لديها المهارة الكافية لقيادة فرق العمل بفاعلية، تملك مهارة الإبداع في بناء الخطط التنموية والاستراتيجية والتشغيلية، ومهارة كتابة المحتوى إضافةً إلى كتابة الخطابات والتقارير، لديها الخبرة الواسعة في إدارة مواقع التواصل الاجتماعي، ومهارة التواصل مع الآخرين. ​

    `,
   img:"../assets/images/Teams/fatema.jpg"

  },

  {
    name: "أ. رهف الزهراني​",
    title: ``,
    content: `
    الدرجة العلمية: بكالوريوس في إدارة الأعمال ​
    مدة الخبرة العملية: سنتين خبرة عملية.  ​
    مجالات العمل: ​

إدارة المشاريع ​

تحليل الأعمال ​

بناء المخرجات ​

​
تطوير الأعمال ​

بناء مؤسسي​

​
أبزر الدورات التدريبية: ​

مهارة المحادثة باللغة الإنجليزية​

أساسيات ومهارات التسويق الرقمي​

خدمة العملاء/ تعامل احترافي ​

​
لديها القدرة على اكتشاف المشكلات ومعالجتها، تملك مهارات التواصل العالية والقدرة على خدمة العملاء بفاعلية، والجدولة وتسجيل المهام،  تملك مهارة التخطيط والبحث ومهارات  قيادية، ومهارات تقنيّة في استخدام الحاسب الآلي إضافةً إلى إجاد برامج Microsoft office  , ولديها الخبرة الكافية في إعداد التقارير وامتلاك خبرة في إدارة المشاريع وتطويرها. ​

    `,
   img:"../assets/images/Teams/fahaf.jpg"

  },
  
  {
    name: "أ. وجدان الرصيص​",
    title: ``,
    content: `
    الدرجة العلمية: بكالوريوس نظم معلومات إدارية ​
    مدة الخبرة العملية: سنة خبرة عملية.  ​
    مجالات العمل: ​

إدارة المشاريع ​

تحليل الأعمال ​

بناء المخرجات ​

تطوير الأعمال ​

أبزر الدورات التدريبية: ​

Personal information​

Management (outlook)​

Leadership essentials​


تملك مهارات إدارية وفنية ومهارات تحليلة عالية، وقد شاركت في أنشطة المشروع من حيث جمع البيانات، والاتصالات، والتنفيذ، إضافةً إلى تطوير المحتوى وإعداد التقارير الدورية عبر برامج الـMicrosoft office. وبرنامج الووردبريس في إنشاء صفحات عبر الموقع WPForms, ولديها الخبرة الكافية في إعداد المخرجات الفنية  وإدارة المشاريع باحترافية.​

​
    `,
   img:"../assets/images/Teams/wegdan.jpg"

  },

  {
    name: "م. يوسف داهم ​",
    title: ``,
    content: `
    الدرجة العلمية: ماجستير إدارة المنظمات غير الربحية ​
    مدة الخبرة العملية: 7 سنوات خبرة عملية.  ​
    مجالات العمل: ​

تطوير الأعمال​

إدارة مشاريع​

الاستشارات والتدريب​

​
أبزر الشهادات المهنية: ​

PMP​

PMD​

بكالوريوس هندسة كيميائية​

CSP​

P3.express​

عمل في مجال الاستشارات والتدريب كمستشار إداري ومؤلف ومدرب وميسر ومطور للبرامج التدريبية في مجال الاستراتيجية والخطط الاستراتيجية والتميز المؤسسي للمنظمات، وإدارة الشراكات، ومن مهارته: إدارة فريق العمل، حل المشاكل ومهارات التحليل النقدي والتفكير، ابتكار تصميم المشاريع، وإعداد العروض الفنية والمالية، إعداد البحوث والمخرجات الاستشارية، التدريب.​

    `,
   img:"../assets/images/Teams/imgm.png"

  },


  {
    name: "أ. عائد معزه​",
    title: ``,
    content: `
    الدرجة العلمية: بكالوريوس وسائط متعددة  ​
    مدة الخبرة العملية: 8 سنوات خبرة عملية.  ​
    مجالات العمل: ​

مصمم جرافيك​

مصمم مواقع إلكترونية ​

​

​أبزر الدورات التدريبية: ​

Training in INFO-Graphic​

Animation​

E-work project Freelancer training ​

UI-UX Training​

يملك الخبرة الواسعة في مبادرات التسويق والإعلانات والصور الوطنية لمختلف العلامات التجارية والمؤسسات والشركات الناشئة والشركات المتوسط.، قد قام بتضمين موقع ويب وتجربة المستخدم وتصميم واجهة المستخدم في محفظته، وقد درس ومارس رسم القصة المصورة مع فريق الإنتاج طوال مرحلة ما قبل الإنتاج.​

 ​

    `,
   img:"../assets/images/Teams/imgm.png"

  },

];
