import React, { useState, useRef } from "react";
import { Question } from "../Utils/Data";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

function Home() {
  const [open, setOpen] = useState(-1);
  const handOpene = (i) => setOpen(i);

  //   const progressCircle = useRef(null);
  //   const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    // progressCircle.current.style.setProperty("--progress", 1 - progress);
    // progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };
  let swipimg = ['','','','','','','','','','','','','','','','','','','','','','','','','','','',''];
  return (
    <div>
     
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <div>
            <main class="w-full">
              <section class="w-full bg-blue-seo py-3 relative overflow-hidden pt-28">
                <div class="w-full max-w-[1506px] relative theme-container mx-auto grid grid-cols-6 xl:grid-cols-12 z-10">
                  <img
                    src="./assets/images/home-two/hero-dot-bg.svg"
                    alt="img"
                    class="absolute top-[75px] -left-[145px] moving-h2-hero-main-img"
                  />
                  <div class="col-span-5 my-auto">
                    <h5
                      class="text-4xl md:text-65 tracking-tight font-semibold text-white pr-2"
                      data-depth="-0.28"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      <img
                        src="./assets/images/Raneename.png"
                        alt="ran"
                        width="50%"
                      />

                      <br class="hidden md:block" />
                    </h5>
                    <h3
                      class="text-18 font-medium font-inter  mt-5 md:mt-5 "
                      data-aos="fade-up"
                      data-aos-delay="800"
                    >
                      <span class="aaaaaaaa">
                        شريك التحول الاستراتيجي للمنظمات العاملة في القطاع غير
                        الربحي
                        <br />
                        بالمملكة العربية السعودية
                      </span>
                    </h3>
                    <div
                      class="flex flex-col sm:flex-row gap-3 sm:gap-6 mt-5 md:mt-12"
                      data-aos="fade-up"
                      data-aos-delay="1000"
                    >
                      <a href="#">
                        <button class="btn btn-gradient fs-8 mt-1 d-inline-flex">
                          <span> للمزيد</span>
                        </button>
                      </a>
                    </div>
                  </div>

                  <img
                    src="./assets/images/home-two/hero-imgrn.png"
                    alt="img"
                    class="col-span-7 self-end relative left-0 2xl:-left-52 bottom-0 moving-h2-hero-main-img"
                  />
                </div>
                <div class="rotating_circle z-0 absolute flex justify-center items-center -bottom-4 xl:-bottom-[150px] left-0 xl:-left-20 h-0 w-full xl:w-[880px] 2xl:max-w-[1250px]">
                  <img
                    src="./assets/images/home-two/round-shape-1.svg"
                    alt=""
                    class="w-full"
                  />
                  <img
                    src="./assets/images/home-two/round-shape-2.svg"
                    alt=""
                    class="absolute w-10/12"
                  />
                  <img
                    src="./assets/images/home-two/round-shape-3.svg"
                    alt=""
                    class="absolute w-8/12"
                  />
                  <img
                    src="./assets/images/home-two/round-shape-4.svg"
                    alt=""
                    class="absolute w-6/12"
                  />
                </div>
              </section>

              <section class="w-full py-16 md:py-32">
                <div class="w-full theme-container mx-auto flex items-center flex-col text-center">
                  <h1
                    class="max-w-[813px] font-semibold text-4xl sm:text-40 tracking-tight text-app-dark text-center mt-[18px]"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    لماذا رنين؟
                  </h1>
                  <div class="grid gird-cols-4 md:grid-cols-8 xl:grid-cols-12 gap-[30px] mt-8 md:mt-[70px]">
                    <div
                      data-aos="fade-up"
                      data-aos-delay="100"
                      class="col-span-4"
                    >
                      <div class="p-5 md:p-12 relative overflow-hidden rounded-[20px] border-[1.2px] border-purple/10 h2_features_card_bg cursor-pointer group hover:border-purple transition-all duration-300">
                        <div class="hidden absolute -bottom-1 -right-1 group-hover:flex justify-center items-center w-2 h-2 z-0">
                          <div class="animated_circle_sm bg-white/10"></div>
                          <div class="animated_circle_sm2 bg-white/10"></div>
                          <div class="animated_circle_sm3 bg-white/10"></div>
                          <div class="animated_circle_sm4 bg-white/10"></div>
                        </div>
                        <div class="relative z-10">
                          <h1 class="text-24 mb-5 text-blue-seo font-semibold pt-1 transition-all duration-300 underlines-s">
                            شراكات تنموية
                          </h1>
                          <p class="text-paragraph mt-4">
                            وصول أكبر للمانحين والداعمين وآفاق شراكة واسعة
                            وفاعلة مع القطاعين الحكومي والقطاع الخاص والمنظمات
                            ذات العلاقة
                          </p>
                          <img
                            src="./assets/images/home-two/features/imweb3.png"
                            alt="img"
                            class="mt-10 max-w-[310px] w-full object-cover max-h-[300px] overflow-hidden rounded-lg group-hover:scale-105 transition-all duration-300"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      data-aos="fade-up"
                      data-aos-delay="100"
                      class="col-span-4"
                    >
                      <div class="p-5 md:p-12 relative overflow-hidden rounded-[20px] border-[1.2px] border-purple/10 h2_features_card_bg cursor-pointer group hover:border-purple transition-all duration-300">
                        <div class="hidden absolute -bottom-1 -right-1 group-hover:flex justify-center items-center w-2 h-2 z-0">
                          <div class="animated_circle_sm bg-white/10"></div>
                          <div class="animated_circle_sm2 bg-white/10"></div>
                          <div class="animated_circle_sm3 bg-white/10"></div>
                          <div class="animated_circle_sm4 bg-white/10"></div>
                        </div>
                        <div class="relative z-10">
                          <h1 class="text-24 mb-5 text-blue-seo font-semibold pt-1 transition-all duration-300 underlines-s">
                            التمويل و الدعم
                          </h1>
                          <p class="text-paragraph mt-4">
                            قدرة أكبر على جلب التمويل والدعم وتحريك المجتمع
                            لمساندة منظمات القطاع الثالث وتمكينها من القيام
                            بأنشطتها بفعالية
                          </p>
                          <img
                            src="./assets/images/home-two/features/imweb2.png"
                            alt="img"
                            class="mt-10 max-w-[310px] w-full object-cover max-h-[300px] overflow-hidden rounded-lg group-hover:scale-105 transition-all duration-300"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      data-aos="fade-up"
                      data-aos-delay="200"
                      class="col-span-4"
                    >
                      <div class="p-5 md:p-12 relative overflow-hidden rounded-[20px] border-[1.2px] border-purple/10 h2_features_card_bg cursor-pointer group hover:border-purple transition-all duration-300">
                        <div class="hidden absolute -bottom-1 -right-1 group-hover:flex justify-center items-center w-2 h-2 z-0">
                          <div class="animated_circle_sm bg-white/10"></div>
                          <div class="animated_circle_sm2 bg-white/10"></div>
                          <div class="animated_circle_sm3 bg-white/10"></div>
                          <div class="animated_circle_sm4 bg-white/10"></div>
                        </div>
                        <div class="relative z-10">
                          <h1 class="text-24 mb-5 text-blue-seo font-semibold pt-1 transition-all duration-300 underlines-s">
                            تحقيق الأثر المستدام
                          </h1>

                          <p class="text-paragraph mt-4">
                            تحقيق الأثر المجتمعي المستدام وزيادة مساهمة منظمات
                            القطاع الثالث في التنمية الاجتماعية في المملكة
                          </p>
                          <img
                            src="./assets/images/home-two/features/imweb1.png"
                            alt="img"
                            class="mt-10 max-w-[310px] w-full object-cover max-h-[300px] overflow-hidden rounded-lg group-hover:scale-105 transition-all duration-300"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section class="w-full py-16 md:py-[130px]">
                <div class="theme-container mx-auto w-full">
                  <div class="grid grid-cols-6 lg:grid-cols-12 gap-10 lg:gap-0">
                    <div class="col-span-6 relative">
                      <div id="map"></div>

                      <div class="d-flex justify-center">
                        <div class="p-3 sm:p-5 rounded-[30px] right-0 sm:right-32 absolute top-0 moving-h2-hero-main-img min-w-[180px]">
                          <h1
                            class="text-[42px] leading-[56px] tracking-tight font-semibold w-full text-center "
                            data-scroll-qs="scroll"
                            data-count-qs="163"
                            data-type-qs=""
                            data-speed-qs="2000"
                          ></h1>
                          <p class="text-base leading-6 flex w-full justify-center items-center gap-1">
                            <svg
                              width="11"
                              height="11"
                              viewBox="0 0 11 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.65692 0.828427H9.31378M9.31378 0.828427V6.48528M9.31378 0.828427L0.828497 9.31371"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <span>عميل</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-span-6 lg:pl-[87px]">
                      <h1
                        class="max-w-[813px] font-semibold text-3xl sm:text-40 text-app-dark text-start mt-[18px]"
                        data-aos="fade-up"
                        data-aos-delay="100"
                      >
                        من نحن
                      </h1>
                      <p
                        class="mt-3 md:mt-5 text-paragraph"
                        data-aos="fade-up"
                        data-aos-delay="150"
                      >
                        شركة رنين التنمية لخدمات الأعمال هي شركة ذات مسؤولية
                        محدودة تعمل في مجال الاستشارات الإدارية العليا تخصصت في
                        رفد القطاع غير الربحي، حيث نعمل كشريك تحول جنبًا إلى جنب
                        مع مكونات القطاع غير الربحي لتعزيز بنائها المؤسسي وتقديم
                        حلول إدارية وتقنية واستشارية مبتكرة تسهم في استدامتها
                        ونموها.
                      </p>

                      <h1
                        class="max-w-[813px] mt-3 md:mt-10 font-semibold text-3xl sm:text-40 text-app-dark text-start mt-[18px]"
                        data-aos="fade-up"
                        data-aos-delay="100"
                      >
                        غرضنا
                      </h1>
                      <p
                        class="mt-3 md:mt-5 text-paragraph"
                        data-aos="fade-up"
                        data-aos-delay="150"
                      >
                        نسعى في شركة رنين التنمية لخدمات الأعمال إلى تمكين
                        المنظمات العاملة في القطاع غير الربحي من تحقيق أهدافها
                        بكفاءة عالية من خلال تقديم مجموعة شاملة من الخدمات
                        الاستشارية التي تضمن لها الازدهار والتأثير الإيجابي
                        المستدام في المجتمعات ولدى الفئات التي تخدمها.
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section class="w-full -top-2/4 bg-black-seo relative overflow-hidden">
                <div class="theme-container w-full mx-auto relative z-10">
                  <div class="grid grid-cols-1 lg:grid-cols-2 gap-[30px] mt-7 md:mt-[70px] text-start">
                    <div
                      data-aos-duration="1000"
                      data-aos="fade-left"
                      data-aos-delay="100"
                      class="col-span-1 p-5 md:p-[50px] rounded-3xl flex flex-col md:flex-row gap-[50px] relative overflow-hidden group border border-white/5 transition-all duration-300 before:inline-block before:w-[1000px] before:h-[1000px] before:rounded-full before:bg-blue-seo before:absolute before:z-0 z-10 before:-bottom-[1000px] before:-right-[1000px] before:transition-all before:duration-1000 hover:before:-bottom-[300px] hover:before:-right-[100px] back-card"
                    >
                      <div class="hidden absolute -bottom-1 -right-1 group-hover:flex justify-center items-center w-2 h-2 z-0">
                        <div class="animated_circle_sm bg-white/10"></div>
                        <div class="animated_circle_sm2 bg-white/10"></div>
                        <div class="animated_circle_sm3 bg-white/10"></div>
                        <div class="animated_circle_sm4 bg-white/10"></div>
                      </div>
                      <div class="relative z-10">
                        <img
                          src="./assets/images/home-two/Solutions/1.png"
                          alt="hom"
                        />
                      </div>
                      <div class="relative z-10">
                        <h1 class="group-hover:text-white font-semibold text-22 ">
                          رؤيتنا
                        </h1>
                        <p class="group-hover:text-white  transition-all duration-300 mt-4">
                          نطمح في شركة رنين التنمية لخدمات الأعمال إلى أن نكون
                          شريك التحول الاستراتيجي للمنظمات العاملة في القطاع غير
                          الربحي بالمملكة العربية السعودية والمساهمة في إحداث
                          تغيير إيجابي ملموس عبر مجتمع حيوي.
                        </p>
                      </div>
                    </div>

                    <div
                      data-aos-duration="1000"
                      data-aos="fade-left"
                      data-aos-delay="100"
                      class="col-span-1 p-5 md:p-[50px] rounded-3xl flex flex-col md:flex-row gap-[50px] relative overflow-hidden group border border-white/5 transition-all duration-300 before:inline-block before:w-[1000px] before:h-[1000px] before:rounded-full before:bg-blue-seo before:absolute before:z-0 z-10 before:-bottom-[1000px] before:-right-[1000px] before:transition-all before:duration-1000 hover:before:-bottom-[300px] hover:before:-right-[100px] back-card"
                    >
                      <div class="hidden absolute -bottom-1 -right-1 group-hover:flex justify-center items-center w-2 h-2 z-0">
                        <div class="animated_circle_sm bg-white/10"></div>
                        <div class="animated_circle_sm2 bg-white/10"></div>
                        <div class="animated_circle_sm3 bg-white/10"></div>
                        <div class="animated_circle_sm4 bg-white/10"></div>
                      </div>
                      <div class="relative z-10">
                        <img
                          src="./assets/images/home-two/Solutions/2.png"
                          alt="hom"
                        />
                      </div>
                      <div class="relative z-10">
                        <h1 class="group-hover:text-white font-semibold text-22 ">
                          رسالتنا
                        </h1>
                        <p class="group-hover:text-white  transition-all duration-300 mt-4">
                          نعمل على تصميم تدخلات تنموية مستدامة ذات تأثير إيجابي
                          تتلاءم مع السياقات الوطنية والتوجهات التنموية للمملكة
                          العربية السعودية من خلال تقديم منتجات مستدامة ومسارات
                          عمل متكاملة وكيانات مبتكرة فاعلة.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section class="logos__marquees text-center">
                <div class="logos__marquee">
                  <div class="marquee__logos">
                    <img
                      src="./assets/images/home-two/customer/logo1.png"
                      width="125"
                      height="24"
                      loading="eager"
                      alt="Zendesk"
                      class="intro-logos_logo"
                    />
                    <img
                      src="./assets/images/home-two/customer/logo2.png"
                      width="125"
                      height="24"
                      loading="eager"
                      alt="Zendesk"
                      class="intro-logos_logo"
                    />
                    <img
                      src="./assets/images/home-two/customer/logo3.png"
                      width="125"
                      height="24"
                      loading="eager"
                      alt="Zendesk"
                      class="intro-logos_logo"
                    />
                    <img
                      src="./assets/images/home-two/customer/logo4.png"
                      width="125"
                      height="24"
                      loading="eager"
                      alt="Zendesk"
                      class="intro-logos_logo"
                    />
                    <img
                      src="./assets/images/home-two/customer/logo5.png"
                      width="125"
                      height="24"
                      loading="eager"
                      alt="Zendesk"
                      class="intro-logos_logo"
                    />
                    <img
                      src="./assets/images/home-two/customer/logo6.png"
                      width="125"
                      height="24"
                      loading="eager"
                      alt="Zendesk"
                      class="intro-logos_logo"
                    />
                  </div>
                  <div class="marquee__logos" aria-hidden="true">
                    <img
                      src="./assets/images/home-two/customer/logo1.png"
                      width="125"
                      height="24"
                      loading="eager"
                      alt="Zendesk"
                      class="intro-logos_logo"
                    />
                    <img
                      src="./assets/images/home-two/customer/logo2.png"
                      width="125"
                      height="24"
                      loading="eager"
                      alt="Zendesk"
                      class="intro-logos_logo"
                    />
                    <img
                      src="./assets/images/home-two/customer/logo3.png"
                      width="125"
                      height="24"
                      loading="eager"
                      alt="Zendesk"
                      class="intro-logos_logo"
                    />
                    <img
                      src="./assets/images/home-two/customer/logo4.png"
                      width="125"
                      height="24"
                      loading="eager"
                      alt="Zendesk"
                      class="intro-logos_logo"
                    />
                    <img
                      src="./assets/images/home-two/customer/logo5.png"
                      width="125"
                      height="24"
                      loading="eager"
                      alt="Zendesk"
                      class="intro-logos_logo"
                    />
                    <img
                      src="./assets/images/home-two/customer/logo6.png"
                      width="125"
                      height="24"
                      loading="eager"
                      alt="Zendesk"
                      class="intro-logos_logo"
                    />
                  </div>
                </div>
              </section>

              <section class="w-full py-16 md:py-[130px] -top-2/4 bg-black-seo relative overflow-hidden">
                <div class="theme-container w-full mx-auto relative z-10">
                  <div
                    class="flex flex-col sm:flex-row w-full justify-center items-center mt-5"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <h1 class="max-w-[813px] font-semibold text-3xl sm:text-40 text-app-dark text-center mt-[5px]">
                      كيف نبني حلول الأعمال
                    </h1>
                  </div>
                  <p
                    class="pt-4 text-center"
                    data-aos="fade-up"
                    data-aos-delay="150"
                  >
                    من خلال مجموعة عملية ومتكاملة من التدخلات المتخصصة والدعم
                    الفني لمساعدة المنظمات غير الربحية على النمو
                    <br />
                    المتسارع للوفاء برسالتها وتحقيق النفع المجتمعي المستدام.
                  </p>

                  <div class="grid grid-cols-1 lg:grid-cols-2 gap-[30px] mt-7 md:mt-[70px] text-start">
                    <div
                      data-aos-duration="1000"
                      data-aos="fade-left"
                      data-aos-delay="100"
                      class="col-span-1 p-5 md:p-[50px] rounded-3xl flex flex-col md:flex-row gap-[50px] relative overflow-hidden group border border-white/5 transition-all duration-300 before:inline-block before:w-[1000px] before:h-[1000px] before:rounded-full before:bg-blue-seo before:absolute before:z-0 z-10 before:-bottom-[1000px] before:-right-[1000px] before:transition-all before:duration-1000 hover:before:-bottom-[300px] hover:before:-right-[100px] back-card"
                    >
                      <div class="hidden absolute -bottom-1 -right-1 group-hover:flex justify-center items-center w-2 h-2 z-0">
                        <div class="animated_circle_sm bg-white/10"></div>
                        <div class="animated_circle_sm2 bg-white/10"></div>
                        <div class="animated_circle_sm3 bg-white/10"></div>
                        <div class="animated_circle_sm4 bg-white/10"></div>
                      </div>
                      <div class="relative z-10">
                        <img src="./assets/images/home-two/Solutions/1.png" />
                      </div>
                      <div class="relative z-10">
                        <h1 class="group-hover:text-white font-semibold text-22 ">
                          بناء مؤسسي
                        </h1>
                        <p class="group-hover:text-white  transition-all duration-300 mt-4">
                          تعزيز البناء المؤسسي لمنظمات القطاع الثالث وتوفير
                          مكتبة مرجعية من السياسات والإجراءات الإدارية والمالية
                          الأساسية وتكييفها بما يتناسب مع احتياجات كل منظمة
                        </p>
                      </div>
                    </div>

                    <div
                      data-aos-duration="1000"
                      data-aos="fade-left"
                      data-aos-delay="100"
                      class="col-span-1 p-5 md:p-[50px] rounded-3xl flex flex-col md:flex-row gap-[50px] relative overflow-hidden group border border-white/5 transition-all duration-300 before:inline-block before:w-[1000px] before:h-[1000px] before:rounded-full before:bg-blue-seo before:absolute before:z-0 z-10 before:-bottom-[1000px] before:-right-[1000px] before:transition-all before:duration-1000 hover:before:-bottom-[300px] hover:before:-right-[100px] back-card"
                    >
                      <div class="hidden absolute -bottom-1 -right-1 group-hover:flex justify-center items-center w-2 h-2 z-0">
                        <div class="animated_circle_sm bg-white/10"></div>
                        <div class="animated_circle_sm2 bg-white/10"></div>
                        <div class="animated_circle_sm3 bg-white/10"></div>
                        <div class="animated_circle_sm4 bg-white/10"></div>
                      </div>
                      <div class="relative z-10">
                        <img src="./assets/images/home-two/Solutions/2.png" />
                      </div>
                      <div class="relative z-10">
                        <h1 class="group-hover:text-white font-semibold text-22 ">
                          تأهيل نوعي
                        </h1>
                        <p class="group-hover:text-white  transition-all duration-300 mt-4">
                          مجموعة متكاملة من الورش التدريبية ذات المسار السريع
                          باستخدام مواد متخصصة وأدوات تطبيقية تتلاءم مع منظمات
                          القطاع الثالث الناشئة
                        </p>
                      </div>
                    </div>

                    <div
                      data-aos-duration="1000"
                      data-aos="fade-left"
                      data-aos-delay="200"
                      class="col-span-1 p-5 md:p-[50px] rounded-3xl flex flex-col md:flex-row gap-[50px] relative overflow-hidden group border border-white/5 transition-all duration-300 before:inline-block before:w-[1000px] before:h-[1000px] before:rounded-full before:bg-blue-seo before:absolute before:z-0 z-10 before:-bottom-[1000px] before:-right-[1000px] before:transition-all before:duration-1000 hover:before:-bottom-[300px] hover:before:-right-[100px] back-card"
                    >
                      <div class="hidden absolute -bottom-1 -right-1 group-hover:flex justify-center items-center w-2 h-2 z-0">
                        <div class="animated_circle_sm bg-white/10"></div>
                        <div class="animated_circle_sm2 bg-white/10"></div>
                        <div class="animated_circle_sm3 bg-white/10"></div>
                        <div class="animated_circle_sm4 bg-white/10"></div>
                      </div>
                      <div class="relative z-10">
                        <img src="./assets/images/home-two/Solutions/3.png" />
                      </div>
                      <div class="relative z-10">
                        <h1 class="group-hover:text-white font-semibold text-22 ">
                          بوابة إلكترونية
                        </h1>
                        <p class="group-hover:text-white  transition-all duration-300 mt-4">
                          بالشراكة مع بورتال 365 نوفر إمكانية الدخول على نظام
                          إدارة المشاريع التنموية الذي تم إعداده وفق أفضل
                          الممارسات العالمية في العمل التنموي
                        </p>
                      </div>
                    </div>

                    <div
                      data-aos-duration="1000"
                      data-aos="fade-left"
                      data-aos-delay="100"
                      class="col-span-1 p-5 md:p-[50px] rounded-3xl flex flex-col md:flex-row gap-[50px] relative overflow-hidden group border border-white/5 transition-all duration-300 before:inline-block before:w-[1000px] before:h-[1000px] before:rounded-full before:bg-blue-seo before:absolute before:z-0 z-10 before:-bottom-[1000px] before:-right-[1000px] before:transition-all before:duration-1000 hover:before:-bottom-[300px] hover:before:-right-[100px] back-card"
                    >
                      <div class="hidden absolute -bottom-1 -right-1 group-hover:flex justify-center items-center w-2 h-2 z-0">
                        <div class="animated_circle_sm bg-white/10"></div>
                        <div class="animated_circle_sm2 bg-white/10"></div>
                        <div class="animated_circle_sm3 bg-white/10"></div>
                        <div class="animated_circle_sm4 bg-white/10"></div>
                      </div>
                      <div class="relative z-10">
                        <img src="./assets/images/home-two/Solutions/4.png" />
                      </div>
                      <div class="relative z-10">
                        <h1 class="group-hover:text-white font-semibold text-22 ">
                          مساندة وتوجيه
                        </h1>
                        <p class="group-hover:text-white  transition-all duration-300 mt-4">
                          تحصل المنظمات على مساندة فنية أثناء فترة التحاقها
                          بالبرنامج وذلك بواسطة مجموعة من أفضل خبراء في العمل
                          التنموي
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="pt-16 md:pt-[130px]">
                    <div
                      class="flex flex-col sm:flex-row w-full justify-center items-center mt-5"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      <h1 class="max-w-[813px] font-semibold text-3xl sm:text-40 text-app-dark text-center mt-[18px]">
                        العمل الرئيسي
                      </h1>
                    </div>
                    <div class="swiper case_study_slider mt-10 md:mt-16 overflow-hidden">
                      <div class="swiper-wrapper">
                        <div
                          class="swiper-slide text-center "
                          data-aos="fade-up"
                          data-aos-delay="190"
                          role="group"
                          aria-label="1 / 1"
                          data-swiper-slide-index="0"
                          style={{ width: "396.667px", marginLeft: "50px" }}
                        >
                          <div class="bg-white rounded-[20px] pt-7 pb-5 px-2.5 relative group overflow-hidden transition-all duration-300 before:inline-block before:w-[800px] before:h-[800px] before:rounded-full  before:absolute before:z-0 z-10 before:-bottom-[800px] before:-right-[800px] before:transition-all before:duration-1000 hover:before:-bottom-[150px] hover:before:-right-[150px] ">
                            <div class="px-2 md:px-10 relative z-10">
                              <a href>
                                <h1 class="text-24 text-blue-seo font-semibold pt-1 group-hover:text-cf9 transition-all duration-300 underlines">
                                  بناء القدرات
                                </h1>
                              </a>
                            </div>
                            <img
                              src="./assets/images/home-two/case_study/img1.png"
                              alt
                              class="max-w-[390px] w-full rounded-2xl mt-10 relative z-10"
                            />
                            <div class="hidden absolute bottom-10 -right-1 group-hover:flex justify-center items-center w-2 h-2 z-0">
                              <div class="animated_circle_sm bg-white/10"></div>
                              <div class="animated_circle_sm2 bg-white/10"></div>
                              <div class="animated_circle_sm3 bg-white/10"></div>
                              <div class="animated_circle_sm4 bg-white/10"></div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="swiper-slide text-center"
                          data-aos="fade-up"
                          data-aos-delay="170"
                          role="group"
                          aria-label="1 / 1"
                          data-swiper-slide-index="0"
                          style={{ width: "396.667px", marginLeft: "50px" }}
                        >
                          <div class="bg-white rounded-[20px] pt-7 pb-5 px-2.5 relative group overflow-hidden transition-all duration-300 before:inline-block before:w-[800px] before:h-[800px] before:rounded-full  before:absolute before:z-0 z-10 before:-bottom-[800px] before:-right-[800px] before:transition-all before:duration-1000 hover:before:-bottom-[150px] hover:before:-right-[150px]">
                            <div class="px-2 md:px-10 relative z-10">
                              <a href>
                                <h1 class="text-24 text-blue-seo font-semibold pt-1  transition-all duration-300 underlines">
                                  حلول الأعمال
                                </h1>
                              </a>
                            </div>
                            <img
                              src="./assets/images/home-two/case_study/img2.png"
                              alt
                              class="max-w-[390px] w-full rounded-2xl mt-10 relative z-10"
                            />
                            <div class="hidden absolute bottom-10 -right-1 group-hover:flex justify-center items-center w-2 h-2 z-0">
                              <div class="animated_circle_sm bg-white/10"></div>
                              <div class="animated_circle_sm2 bg-white/10"></div>
                              <div class="animated_circle_sm3 bg-white/10"></div>
                              <div class="animated_circle_sm4 bg-white/10"></div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="swiper-slide text-center"
                          data-aos="fade-up"
                          data-aos-delay="150"
                          role="group"
                          aria-label="1 / 1"
                          data-swiper-slide-index="0"
                          style={{ width: "396.667px", marginLeft: "50px" }}
                        >
                          <div class="bg-white rounded-[20px] pt-7 pb-5 px-2.5 relative group overflow-hidden transition-all duration-300 before:inline-block before:w-[800px] before:h-[800px] before:rounded-full  before:absolute before:z-0 z-10 before:-bottom-[800px] before:-right-[800px] before:transition-all before:duration-1000 hover:before:-bottom-[150px] hover:before:-right-[150px]">
                            <div class="px-2 md:px-10 relative z-10">
                              <a href>
                                <h1 class="text-24 text-blue-seo font-semibold pt-1  transition-all duration-300 underlines">
                                  الاستشارات
                                </h1>
                              </a>
                            </div>
                            <img
                              src="./assets/images/home-two/case_study/img3.png"
                              alt
                              class="max-w-[390px] w-full rounded-2xl mt-10 relative z-10"
                            />
                            <div class="hidden absolute bottom-10 -right-1 group-hover:flex justify-center items-center w-2 h-2 z-0">
                              <div class="animated_circle_sm bg-white/10"></div>
                              <div class="animated_circle_sm2 bg-white/10"></div>
                              <div class="animated_circle_sm3 bg-white/10"></div>
                              <div class="animated_circle_sm4 bg-white/10"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section class="w-full py-5 md:py-[50px] -top-2/4 relative overflow-hidden">
                <div class="theme-container w-full mx-auto relative z-10">
                  <div class="pt-5 md:pt-[100px]">
                    <div class="flex flex-col sm:flex-row w-full justify-center text-center items-end mt-10">
                      <h1
                        class="max-w-[813px] font-semibold text-3xl sm:text-40 text-app-dark text-center mt-[10px] aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        ميادين العمل الرئيسية
                      </h1>
                    </div>
                    <div class="swiper case_study_slider mt-10 md:mt-16 overflow-hidden text-center swiper-initialized swiper-horizontal swiper-rtl swiper-backface-hidden">
                      <div
                        class="swiper-wrapper"
                        id="swiper-wrapper-47410c100423d1108e9"
                        aria-live="off"
                        style={{
                          // transitionDuration: "0ms",
                          // transform: "translate3d(1340px, 0px, 0px)",
                          // transitionDelay: "0ms",
                          // background: 'red'
                        }}
                      >
                        <Swiper
                          spaceBetween={50}
                          slidesPerView={3}
                          onSlideChange={() => console.log("slide change")}
                          onSwiper={(swiper) => console.log(swiper)}

                          // rewind={false}
                          // modules={[Navigation]}

                          centeredSlides={true}
                          loop
                          autoplay={{
                            delay: 2500,
                            // disableOnInteraction: false,
                            stopOnLastSlide: false,
                          }}
                          // pagination={{
                          //   clickable: true,
                          // }}
                          navigation={false}
                          modules={[Autoplay, Pagination, Navigation]}
                          onAutoplayTimeLeft={onAutoplayTimeLeft}
                          className="mySwiper"

                        >
                          <SwiperSlide>
                            <div
                              class="swiper-slide bg-black-seo aos-init aos-animate"
                              data-aos="fade-up"
                              role="group"
                              aria-label="2 / 6"
                              data-swiper-slide-index="1"
                              style={{ width: "396.667px", marginLeft: "50px" }}
                            >
                              <div class="bg-black-seo rounded-[20px] pt-7 pb-5 px-2.5 relative group overflow-hidden transition-all duration-300 before:inline-block before:w-[800px] before:h-[800px] before:rounded-full before:bg-blue-seo before:absolute before:z-0 z-10 before:-bottom-[800px] before:-right-[800px] before:transition-all before:duration-1000 hover:before:-bottom-[150px] hover:before:-right-[150px]">
                                <div class="px-2 md:px-10 relative z-10">
                                  <img
                                    src="./assets/images/home-two/Solutions/imgr2.png"
                                    width="100%"
                                  />
                                  <a href="">
                                    <h1 class="text-24 mb-5 text-blue-seo font-semibold pt-1 group-hover:text-white transition-all duration-300 underlines">
                                      تصميم التدخلات التنموية
                                    </h1>
                                  </a>

                                  <p class="leading-6 text-paragraph text-blue-seo group-hover:text-white delay-300 transition-all duration-300 ease-in-out">
                                    تعمل المنظمات على تنفيذ تدخلات موجة بالنتائج
                                    وتحقق أثراً مستداماً على الفئة المستهدفة. تساعد
                                    المنظمات في إعداد نظرية التغيير للتدخلات التي
                                    تنفذها وتحديد نوع وأولويات هذه التدخلات. وكذلك
                                    معرفة الفرص المحتملة للتغيير نحو الأفضل، وإعداد
                                    مؤشرات نجاح واقعية تتناسب مع قدرة المنظمة على
                                    تحقيق التغيير المنشود.
                                  </p>
                                </div>

                                <div class="hidden absolute bottom-10 -right-1 group-hover:flex justify-center items-center w-2 h-2 z-0">
                                  <div class="animated_circle_sm bg-white/10"></div>
                                  <div class="animated_circle_sm2 bg-white/10"></div>
                                  <div class="animated_circle_sm3 bg-white/10"></div>
                                  <div class="animated_circle_sm4 bg-white/10"></div>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div
                              class="swiper-slide bg-black-seo aos-init aos-animate"
                              data-aos="fade-up"
                              role="group"
                              aria-label="3 / 6"
                              data-swiper-slide-index="2"
                              style={{ width: "396.667px", marginLeft: "50px" }}
                            >
                              <div class="bg-black-seo rounded-[20px] pt-7 pb-5 px-2.5 relative group overflow-hidden transition-all duration-300 before:inline-block before:w-[800px] before:h-[800px] before:rounded-full before:bg-blue-seo before:absolute before:z-0 z-10 before:-bottom-[800px] before:-right-[800px] before:transition-all before:duration-1000 hover:before:-bottom-[150px] hover:before:-right-[150px]">
                                <div class="px-2 md:px-10 relative z-10">
                                  <img
                                    src="./assets/images/home-two/Solutions/imgr3.png"
                                    width="100%"
                                  />
                                  <a href="">
                                    <h1 class="text-24 mb-5 text-blue-seo font-semibold pt-1 group-hover:text-white transition-all duration-300 underlines">
                                      جلب التمويل والدعم
                                    </h1>
                                  </a>

                                  <p class="leading-6 text-paragraph text-blue-seo group-hover:text-white delay-300 transition-all duration-300 ease-in-out">
                                    يمثل جلب الدعم والتمويل أهم التحديات التي تواجه
                                    منظمات القطاع الثالث وطريقها نحو الاستدامة
                                    المالية. تساند المنظمات لمعرفة توجهات المانحين
                                    والداعمين وآليات التواصل معهم والحصول على
                                    الموارد. كما نساعدها أيضاً على زيادة تفاعلها مع
                                    المجتمع وتحريك الأصول المجتمعية لدعم مشاريعها
                                    والخدمات التي تقدمها.
                                  </p>
                                </div>

                                <div class="hidden absolute bottom-10 -right-1 group-hover:flex justify-center items-center w-2 h-2 z-0">
                                  <div class="animated_circle_sm bg-white/10"></div>
                                  <div class="animated_circle_sm2 bg-white/10"></div>
                                  <div class="animated_circle_sm3 bg-white/10"></div>
                                  <div class="animated_circle_sm4 bg-white/10"></div>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div
                              class="swiper-slide bg-black-seo aos-init aos-animate swiper-slide-prev"
                              data-aos="fade-up"
                              role="group"
                              aria-label="4 / 6"
                              data-swiper-slide-index="3"
                              style={{ width: "396.667px", marginLeft: "50px" }}
                            >
                              <div class="bg-black-seo rounded-[20px] pt-7 pb-5 px-2.5 relative group overflow-hidden transition-all duration-300 before:inline-block before:w-[800px] before:h-[800px] before:rounded-full before:bg-blue-seo before:absolute before:z-0 z-10 before:-bottom-[800px] before:-right-[800px] before:transition-all before:duration-1000 hover:before:-bottom-[150px] hover:before:-right-[150px]">
                                <div class="px-2 md:px-10 relative z-10">
                                  <img
                                    src="./assets/images/home-two/Solutions/imgr4.png"
                                    width="100%"
                                  />
                                  <a href="">
                                    <h1 class="text-24 mb-5 text-blue-seo font-semibold pt-1 group-hover:text-white transition-all duration-300 underlines">
                                      إدارة المشاريع التنموية
                                    </h1>
                                  </a>

                                  <p class="leading-6 text-paragraph text-blue-seo group-hover:text-white delay-300 transition-all duration-300 ease-in-out">
                                    بعد توقيع اتفاقية المشروع تحتاج المنظمات إلى
                                    منهجية واضحة لإدارة المشاريع وفق أفضل الممارسات.
                                    تتعرف المنظمة خلال هذه المرحلة على آلية إطلاق
                                    المشاريع التنموية وتحديد المسئوليات والصلاحيات
                                    لفريق المشروع واستقطاب العاملين والمتطوعين وضمان
                                    كفاءة عمليات المشروع وبما يحقق الأهداف المحددة.
                                  </p>
                                </div>

                                <div class="hidden absolute bottom-10 -right-1 group-hover:flex justify-center items-center w-2 h-2 z-0">
                                  <div class="animated_circle_sm bg-white/10"></div>
                                  <div class="animated_circle_sm2 bg-white/10"></div>
                                  <div class="animated_circle_sm3 bg-white/10"></div>
                                  <div class="animated_circle_sm4 bg-white/10"></div>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div
                              class="swiper-slide bg-black-seo aos-init aos-animate swiper-slide-active"
                              data-aos="fade-up"
                              role="group"
                              aria-label="5 / 6"
                              data-swiper-slide-index="4"
                              style={{ width: "396.667px", marginLeft: "50px" }}
                            >
                              <div class="bg-black-seo rounded-[20px] pt-7 pb-5 px-2.5 relative group overflow-hidden transition-all duration-300 before:inline-block before:w-[800px] before:h-[800px] before:rounded-full before:bg-blue-seo before:absolute before:z-0 z-10 before:-bottom-[800px] before:-right-[800px] before:transition-all before:duration-1000 hover:before:-bottom-[150px] hover:before:-right-[150px]">
                                <div class="px-2 md:px-10 relative z-10">
                                  <img
                                    src="./assets/images/home-two/Solutions/imgr5.png"
                                    width="100%"
                                  />
                                  <a href="">
                                    <h1 class="text-24 mb-5 text-blue-seo font-semibold pt-1 group-hover:text-white transition-all duration-300 underlines">
                                      المتابعة والتقييم
                                    </h1>
                                  </a>

                                  <p class="leading-6 text-paragraph text-blue-seo group-hover:text-white delay-300 transition-all duration-300 ease-in-out">
                                    يمثل جلب الدعم والتمويل أهم التحديات التي تواجه
                                    منظمات القطاع الثالث وطريقها نحو الاستدامة
                                    المالية. تساند المنظمات لمعرفة توجهات المانحين
                                    والداعمين وآليات التواصل معهم والحصول على
                                    الموارد. كما نساعدها أيضاً على زيادة تفاعلها مع
                                    المجتمع وتحريك الأصول المجتمعية لدعم مشاريعها
                                    والخدمات التي تقدمها.
                                  </p>
                                </div>

                                <div class="hidden absolute bottom-10 -right-1 group-hover:flex justify-center items-center w-2 h-2 z-0">
                                  <div class="animated_circle_sm bg-white/10"></div>
                                  <div class="animated_circle_sm2 bg-white/10"></div>
                                  <div class="animated_circle_sm3 bg-white/10"></div>
                                  <div class="animated_circle_sm4 bg-white/10"></div>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div
                              class="swiper-slide bg-black-seo aos-init aos-animate swiper-slide-next"
                              data-aos="fade-up"
                              role="group"
                              aria-label="6 / 6"
                              data-swiper-slide-index="5"
                              style={{ width: "396.667px", marginLeft: "50px" }}
                            >
                              <div class="bg-black-seo rounded-[20px] pt-7 pb-5 px-2.5 relative group overflow-hidden transition-all duration-300 before:inline-block before:w-[800px] before:h-[800px] before:rounded-full before:bg-blue-seo before:absolute before:z-0 z-10 before:-bottom-[800px] before:-right-[800px] before:transition-all before:duration-1000 hover:before:-bottom-[150px] hover:before:-right-[150px]">
                                <div class="px-2 md:px-10 relative z-10">
                                  <img
                                    src="./assets/images/home-two/Solutions/imgr6.png"
                                    width="100%"
                                  />
                                  <a href="">
                                    <h1 class="text-24 mb-5 text-blue-seo font-semibold pt-1 group-hover:text-white transition-all duration-300 underlines">
                                      إعداد التقارير والتعلم
                                    </h1>
                                  </a>

                                  <p class="leading-6 text-paragraph text-blue-seo group-hover:text-white delay-300 transition-all duration-300 ease-in-out">
                                    بعد توقيع اتفاقية المشروع تحتاج المنظمات إلى
                                    منهجية واضحة لإدارة المشاريع وفق أفضل الممارسات.
                                    تتعرف المنظمة خلال هذه المرحلة على آلية إطلاق
                                    المشاريع التنموية وتحديد المسئوليات والصلاحيات
                                    لفريق المشروع واستقطاب العاملين والمتطوعين وضمان
                                    كفاءة عمليات المشروع وبما يحقق الأهداف المحددة.
                                  </p>
                                </div>

                                <div class="hidden absolute bottom-10 -right-1 group-hover:flex justify-center items-center w-2 h-2 z-0">
                                  <div class="animated_circle_sm bg-white/10"></div>
                                  <div class="animated_circle_sm2 bg-white/10"></div>
                                  <div class="animated_circle_sm3 bg-white/10"></div>
                                  <div class="animated_circle_sm4 bg-white/10"></div>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div
                              class="swiper-slide bg-black-seo aos-init aos-animate"
                              data-aos="fade-up"
                              role="group"
                              aria-label="1 / 6"
                              data-swiper-slide-index="0"
                              style={{ width: "396.667px", marginLeft: "50px" }}
                            >
                              <div class="bg-black-seo rounded-[20px] pt-7 pb-5 px-2.5 relative group overflow-hidden transition-all duration-300 before:inline-block before:w-[800px] before:h-[800px] before:rounded-full before:bg-blue-seo before:absolute before:z-0 z-10 before:-bottom-[800px] before:-right-[800px] before:transition-all before:duration-1000 hover:before:-bottom-[150px] hover:before:-right-[150px]">
                                <div class="px-2 md:px-10 justify-center text-center relative z-10">
                                  <img
                                    src="./assets/images/home-two/Solutions/imgr1.png"
                                    width="100%"
                                  />
                                  <a href="">
                                    <h1 class="text-24 mb-5 text-blue-seo font-semibold pt-1 group-hover:text-white transition-all duration-300 underlines">
                                      تقييم الاحتياجات المجتمعية
                                    </h1>
                                  </a>

                                  <p class="leading-6 text-paragraph text-blue-seo group-hover:text-white delay-300 transition-all duration-300 ease-in-out">
                                    من خلال تقييم احتياجات المجتمع تكون المنظمات
                                    قادرة على فهم افضل لفئة او المنطقة التي ترغب في
                                    خدمتها – سواء كانت منطقة إدارية بأكملها او
                                    محافظة او حتى حي صغير. نعمل على مساندة المنظمات
                                    في اعداد خارطة الاحتياجات المجتمعية وكذلك قراءة
                                    تقارير التنمية وفهم المؤشرات التنموية في مجالات
                                    ذات العلاقة بمجال عملها.
                                  </p>
                                </div>

                                <div class="hidden absolute bottom-10 -right-1 group-hover:flex justify-center items-center w-2 h-2 z-0">
                                  <div class="animated_circle_sm bg-white/10"></div>
                                  <div class="animated_circle_sm2 bg-white/10"></div>
                                  <div class="animated_circle_sm3 bg-white/10"></div>
                                  <div class="animated_circle_sm4 bg-white/10"></div>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                        </Swiper>

                      </div>
                      {/* <span
                        class="swiper-notification"
                        aria-live="assertive"
                        aria-atomic="true"
                      ></span> */}
                    </div>
                  </div>
                </div>
              </section>

              {/* <div className="autoplay-progress" slot="container-end">
                  <svg viewBox="0 0 48 48" ref={progressCircle}>
                    <circle cx="24" cy="24" r="20"></circle>
                  </svg>
                  <span ref={progressContent}></span>
                </div> */}

              <section class="w-full py-16 md:py-32">
                <div class="w-full theme-container mx-auto flex items-center flex-col">
                  <h1
                    class="max-w-[813px] font-semibold text-24 sm:text-40 tracking-tight text-app-dark text-center mt-2.5 md:mt-[18px]"
                    ata-aos="fade-up"
                    data-aos-delay="100"
                  >
                    أخبار ومقالات رنين
                  </h1>
                  <div class="grid grid-cols-4 xl:grid-cols-8 gap-[90px] mt-0 md:mt-[70px] md:px-28">
                    <div class="col-span-2 hidden xl:grid grid-cols-2 gap-2.5">
                      <div class="col-span-1 relative my-auto">
                        <div class="overlay-vSlider"></div>
                        <div class="swiper vsliderSm max-h-[312px] overflow-hidden">
                          <div class="swiper-wrapper">
                            <div class="swiper-slide">
                              <marquee behavior="scroll" direction="up" height="500px" scrollamount="4" >
                                {
                                  swipimg.map(item => (
                                    <img src="./assets/images/home-two/testimonial/rn1.png" alt="" />
                                  ))
                                }
                              </marquee>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-span-1 relative hidden md:block">
                        <div class="overlay-vSlider"></div>
                        <div class="swiper vslider max-h-[472px] overflow-hidden">
                          <div class="swiper-wrapper">
                            <div class="swiper-slide">
                              <marquee behavior="scroll" direction="down" height="500px" scrollamount="4" >
                                {
                                  swipimg.map(item => (
                                    <img src="./assets/images/home-two/testimonial/rn1.png" alt="" />
                                  ))
                                }
                              </marquee>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="col-span-4 mt-10">
                      <div class="swiper h2-testimonial-slider overflow-hidden">
                        <div class="swiper-wrapper">
                          <div class="swiper-slide">
                            <div class="col-span-1 p-5 md:p-[54px] rounded-3xl flex flex-col items-center relative overflow-hidden group border border-white/5 bg-blue-seo">
                              <div class="absolute -bottom-1 -right-1 flex justify-center items-center w-2 h-2 z-0">
                                <div class="animated_circle_sm bg-white/10"></div>
                                <div class="animated_circle_sm2 bg-white/10"></div>
                                <div class="animated_circle_sm3 bg-white/10"></div>
                                <div class="animated_circle_sm4 bg-white/10"></div>
                              </div>
                              <div class="relative z-10">
                                <svg
                                  width="60"
                                  height="60"
                                  viewBox="0 0 60 60"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clipPath="url(#clip0_460_21874)">
                                    <path
                                      d="M55.9702 11.9941C53.2589 8.94831 49.9682 7.4039 46.1898 7.4039C42.7939 7.4039 39.9151 8.61316 37.6326 10.9979C35.3647 13.3675 34.2147 16.3106 34.2147 19.7458C34.2147 22.9922 35.3763 25.8849 37.6672 28.344C39.688 30.5134 42.2336 31.8933 45.2472 32.4556C44.7202 36.4512 41.1881 40.0677 34.7278 43.2188L33.5645 43.7864L38.3341 52.5919L39.4108 52.0461C53.0727 45.121 59.9997 35.3341 59.9997 22.9571C59.9997 18.6871 58.6441 14.9987 55.9702 11.9941ZM39.3601 49.2765L36.9704 44.8653C44.1567 41.0998 47.7976 36.5732 47.7976 31.3977V30.2896L46.6966 30.1649C43.7312 29.8292 41.3715 28.6805 39.4828 26.6528C37.6077 24.6398 36.6961 22.3807 36.6961 19.7458C36.6961 16.9324 37.5889 14.6323 39.4252 12.7134C41.2467 10.8102 43.4595 9.88499 46.1899 9.88499C49.2725 9.88499 51.8654 11.1145 54.1167 13.6437C56.4058 16.2157 57.5186 19.2621 57.5186 22.957C57.5186 28.6297 55.9213 33.751 52.7711 38.1787C49.7653 42.4033 45.257 46.1331 39.3601 49.2765Z"
                                      fill="white"
                                    />
                                    <path
                                      d="M22.3404 11.9886C19.599 8.94656 16.2947 7.40402 12.519 7.40402C9.11988 7.40402 6.25406 8.61551 4.0016 11.0047C1.76848 13.3733 0.636094 16.3143 0.636094 19.746C0.636094 22.9922 1.79766 25.885 4.08832 28.3441C6.10488 30.5088 8.61996 31.8874 11.5775 32.4524C11.0569 36.4509 7.55414 40.0692 1.14562 43.221L0 43.7842L4.65094 52.596L5.73832 52.0473C19.4621 45.1226 26.4207 35.3352 26.4207 22.9572C26.4206 18.6838 25.0477 14.9934 22.3404 11.9886ZM5.70223 49.2724L3.3777 44.8681C10.5118 41.1011 14.1267 36.5734 14.1267 31.3977V30.292L13.0282 30.1652C10.1236 29.8301 7.79297 28.6812 5.90367 26.6528C4.02855 24.64 3.11719 22.3807 3.11719 19.746C3.11719 16.9289 3.99703 14.6263 5.80676 12.7069C7.5968 10.8082 9.79254 9.88512 12.519 9.88512C15.6047 9.88512 18.2146 11.1164 20.497 13.6495C22.8135 16.2202 23.9395 19.2648 23.9395 22.9572C23.9395 28.6289 22.3352 33.7495 19.1708 38.1768C16.152 42.4002 11.6243 46.1293 5.70223 49.2724Z"
                                      fill="white"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_460_21874">
                                      <rect
                                        width="60"
                                        height="60"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                              <div class="relative z-10">
                                <h1 class=" text-18 md:text-20 font-semibold mt-4 w-full text-center">
                                  المنظمات غير الربحية شريك رئيسي في التنمية
                                </h1>
                                <div class="flex items-center gap-5 mt-5 md:mt-10 mx-auto w-fit">
                                  <div>
                                    <h1 >يناير 30, 2023</h1>
                                    <p class="text-sm leading-7 font-medium ">
                                      by المحرر
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="swiper-slide">
                            <div class="col-span-1 p-5 md:p-[54px] rounded-3xl flex flex-col items-center relative overflow-hidden group border border-white/5 bg-blue-seo">
                              <div class="absolute -bottom-1 -right-1 flex justify-center items-center w-2 h-2 z-0">
                                <div class="animated_circle_sm bg-white/10"></div>
                                <div class="animated_circle_sm2 bg-white/10"></div>
                                <div class="animated_circle_sm3 bg-white/10"></div>
                                <div class="animated_circle_sm4 bg-white/10"></div>
                              </div>
                              <div class="relative z-10">
                                <svg
                                  width="60"
                                  height="60"
                                  viewBox="0 0 60 60"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clipPath="url(#clip0_460_21874)">
                                    <path
                                      d="M55.9702 11.9941C53.2589 8.94831 49.9682 7.4039 46.1898 7.4039C42.7939 7.4039 39.9151 8.61316 37.6326 10.9979C35.3647 13.3675 34.2147 16.3106 34.2147 19.7458C34.2147 22.9922 35.3763 25.8849 37.6672 28.344C39.688 30.5134 42.2336 31.8933 45.2472 32.4556C44.7202 36.4512 41.1881 40.0677 34.7278 43.2188L33.5645 43.7864L38.3341 52.5919L39.4108 52.0461C53.0727 45.121 59.9997 35.3341 59.9997 22.9571C59.9997 18.6871 58.6441 14.9987 55.9702 11.9941ZM39.3601 49.2765L36.9704 44.8653C44.1567 41.0998 47.7976 36.5732 47.7976 31.3977V30.2896L46.6966 30.1649C43.7312 29.8292 41.3715 28.6805 39.4828 26.6528C37.6077 24.6398 36.6961 22.3807 36.6961 19.7458C36.6961 16.9324 37.5889 14.6323 39.4252 12.7134C41.2467 10.8102 43.4595 9.88499 46.1899 9.88499C49.2725 9.88499 51.8654 11.1145 54.1167 13.6437C56.4058 16.2157 57.5186 19.2621 57.5186 22.957C57.5186 28.6297 55.9213 33.751 52.7711 38.1787C49.7653 42.4033 45.257 46.1331 39.3601 49.2765Z"
                                      fill="white"
                                    />
                                    <path
                                      d="M22.3404 11.9886C19.599 8.94656 16.2947 7.40402 12.519 7.40402C9.11988 7.40402 6.25406 8.61551 4.0016 11.0047C1.76848 13.3733 0.636094 16.3143 0.636094 19.746C0.636094 22.9922 1.79766 25.885 4.08832 28.3441C6.10488 30.5088 8.61996 31.8874 11.5775 32.4524C11.0569 36.4509 7.55414 40.0692 1.14562 43.221L0 43.7842L4.65094 52.596L5.73832 52.0473C19.4621 45.1226 26.4207 35.3352 26.4207 22.9572C26.4206 18.6838 25.0477 14.9934 22.3404 11.9886ZM5.70223 49.2724L3.3777 44.8681C10.5118 41.1011 14.1267 36.5734 14.1267 31.3977V30.292L13.0282 30.1652C10.1236 29.8301 7.79297 28.6812 5.90367 26.6528C4.02855 24.64 3.11719 22.3807 3.11719 19.746C3.11719 16.9289 3.99703 14.6263 5.80676 12.7069C7.5968 10.8082 9.79254 9.88512 12.519 9.88512C15.6047 9.88512 18.2146 11.1164 20.497 13.6495C22.8135 16.2202 23.9395 19.2648 23.9395 22.9572C23.9395 28.6289 22.3352 33.7495 19.1708 38.1768C16.152 42.4002 11.6243 46.1293 5.70223 49.2724Z"
                                      fill="white"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_460_21874">
                                      <rect
                                        width="60"
                                        height="60"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                              <div class="relative z-10">
                                <h1 class="text-white text-18 md:text-20 font-semibold mt-4 w-full text-center">
                                  5 أسباب تجعل مُسرّعات الأعمال الاجتماعية الحل
                                  المناسب لمنظمتك
                                </h1>
                                <div class="flex items-center gap-5 mt-5 md:mt-10 mx-auto w-fit">
                                  <div>
                                    <h1 class="text-white">يناير 30, 2023</h1>
                                    <p class="text-sm leading-7 font-medium text-white">
                                      by المحرر
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-span-2 grid-cols-2 gap-2.5 hidden xl:grid">
                      <div class="col-span-1 relative">
                        <div class="overlay-vSlider"></div>
                        <div class="swiper vslider max-h-[472px] overflow-hidden">
                          <div class="swiper-wrapper">
                            <div class="swiper-slide">
                              <marquee behavior="scroll" direction="up" height="500px" scrollamount="4" >
                                {
                                  swipimg.map(item => (
                                    <img src="./assets/images/home-two/testimonial/rn1.png" alt="" />
                                  ))
                                }
                              </marquee>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-span-1 relative my-auto">
                        <div class="overlay-vSlider"></div>
                        <div class="swiper vsliderSm max-h-[312px] overflow-hidden">
                          <div class="swiper-wrapper">
                            <div class="swiper-slide">
                            <marquee behavior="scroll" direction="down" height="500px" scrollamount="4" >
                                {
                                  swipimg.map(item => (
                                    <img src="./assets/images/home-two/testimonial/rn1.png" alt="" />
                                  ))
                                }
                              </marquee>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section class="relative">
                <div class="w-full pb-16 md:pb-[130px] bg-gray-seo relative z-10">
                  <div class="theme-container mx-auto">
                    <div class="flex justify-center items-center px-4 md:px-0 py-10 md:py-20 relative rounded-3xl overflow-hidden">
                      <div class="max-w-[850px] w-full flex justify-center items-center flex-col relative z-10">
                        <h2
                          class="text-24 sm:text-40 font-semibold text-main-black mt-5"
                          ata-aos="fade-up"
                          data-aos-delay="100"
                        >
                          الأسئلة الشائعة
                        </h2>

                        <div class="flex flex-col gap-2.5 w-full mt-5 md:mt-10 p-0 sm:p-5">
                          {Question.map((item, i) => (
                            <div
                              class="py-2.5 md:py-5 px-2 md:px-9 w-full bg-white rounded-[10px] h2-faq-toggler overflow-hidden transition-all duration-300 max-h-fit h-fit"
                              name="faq-1"
                              style={{ height: i == open ? "167px" : "67px" }}
                              onClick={() => handOpene(i)}
                            >
                              <div class="w-full flex justify-between items-center pointer-events-none h-fit">
                                <h1 class="font-semibold sm:text-18 text-main-black">
                                  {item.title}
                                </h1>
                              </div>
                              <p class="mt-3.5 text-paragraph pointer-events-none h-fit">
                                {item.content}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div class="rotating_circle z-0 absolute flex justify-center items-center -bottom-4 xl:-bottom-[150px] left-0 xl:-left-20 h-0 w-full xl:w-[880px] 2xl:max-w-[1250px]">
                        <img
                          src="./assets/images/home-two/round-shape-1.svg"
                          alt=""
                          class="w-full"
                        />
                        <img
                          src="./assets/images/home-two/round-shape-2.svg"
                          alt=""
                          class="absolute w-10/12"
                        />
                        <img
                          src="./assets/images/home-two/round-shape-3.svg"
                          alt=""
                          class="absolute w-8/12"
                        />
                        <img
                          src="./assets/images/home-two/round-shape-4.svg"
                          alt=""
                          class="absolute w-6/12"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex justify-between absolute top-32">
                  <div id="h2-faq-anim-left"></div>
                  <div
                    class="transform rotate-180"
                    id="h2-faq-anim-right"
                  ></div>
                </div>
              </section>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
