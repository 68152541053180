import React, { useState } from "react";
import { NavLink } from "react-router-dom";

function Header() {

const [active,setActive] = useState(false);
const opennav = (v) => setActive(v);

    return (
        <div>

            <div class="xl:flex w-full justify-center h-24 header fixed top-0 z-20 hidden" dir="rtl">
                <header class="flex justify-between max-w-[1619px] relative py-6 mx-auto items-center h-full w-full px-5">
                    <div class="flex gap-28 items-center">
                        <NavLink to="/">
                            <img
                                src="./assets/images/home-two/logo.png"
                                width="73px"
                                alt="logo"
                                class="imglogo"
                            />
                        </NavLink>
                        <ul
                            class="flex gap-10 text-paragraph text-base leading-5 font-medium font-inter"
                        >
                            <li class="group">
                                <NavLink to="/">
                                <a 
                                    class="home-two-nav-item leading-5 relative before:content-['الرئيسية'] w-fit flex gap-2 items-center hover:text-purple"
                                    >
                                    الرئيسية
                                </a>
                                    </NavLink>
                            </li>
                            <li>
                            <NavLink to="/Project">
                                <a
                                    class="home-two-nav-item relative before:content-['المشاريع']"
                                >المشاريع</a>
                                </NavLink>
                            </li>
                            <li>
                            <NavLink to="/WorkTeam">
                                <a
                                    class="home-two-nav-item relative before:content-['فريق العمل']"
                                >فريق العمل</a>
                                </NavLink>
                            </li>
                            {/* <li>
                            <NavLink to="/BusinessCenter">
                                <a
                                    class="home-two-nav-item relative before:content-['مركز الأعمال']"
                                >مركز الأعمال</a>
                                </NavLink>
                            </li> */}
                        </ul>
                    </div>
                    <div class="flex gap-7 items-center">
                        <div class="col-lg-4 col-md-3 d-flex">
                            <ul class="social-links">
                                <li>
                                    <a href="mailto:hala@raneen.com.sa" target="_blank"
                                    ><i class="bi bi-at social-linksicon"></i></a>
                                </li>

                                <li>
                                    <a href="https://www.linkedin.com/company/raneenorg" target="_blank"
                                    ><i class="bi bi-linkedin social-linksicon"></i></a>
                                </li>

                                <li>
                                    <a href="https://x.com/raneenorg?s=11" target="_blank"
                                    ><i class="bi bi-twitter-x social-linksicon"></i></a>
                                </li>
                            </ul>
                        </div>

                        <a target="_blank"
                            href="https://wa.me/+9660582266691/?text=السلام عليكم، وصلت إليكم من خلال موقعكم الإلكتروني، وأريد الاستفسار.."
                        >
                            <button 
                                class="btn btn-gradient-top btn-gradient-top fs-8 mt-1 d-inline-flex"
                            >
                                <span>تواصل بنا</span>
                            </button>
                        </a>
                    </div>
                </header>
            </div>

            <header class="flex xl:hidden flex-col relative" dir="rtl">
                <div
                    class="h-16 bg-white flex items-center justify-between fixed top-0 right-0 z-50 w-full px-2.5"
                >
                     <NavLink to="/">
                    <a width="100px" aria-label="logo">
                        <img src="./assets/images/home-two/logo.png" width="50px" alt="logo" />
                    </a>
                    </NavLink>
                    <button onClick={()=>opennav(!active)}
                        aria-label="mobile-Menu"
                        class="text-main-black text-48 w-6 h-6 toggle_nav_menu"
                        id="#mobile_nav_toggle_menu"
                    >
                        <svg
                            class={`pointer-events-none ${active && 'hidden'} transition-all duration-300`}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                        >
                            <path
                                d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"
                            />
                        </svg>
                        <svg
                            class={`pointer-events-none ${!active && 'hidden'} transition-all duration-300`}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                        >
                            <path
                                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                            />
                        </svg>
                    </button>
                </div>


                <div class="transition-all duration-300">
                    <div class={`w-full h-screen fixed bg-black/80 z-40 -left-full transition-all duration-300 delay-150 ${active && 'active_mobile_nav'}`} id="mobile-nav-div-overlay"></div>
                    <div class={`fixed h-screen overflow-y-scroll bg-white w-full top-16 -left-full z-50 transition-all duration-300 delay-0 ${active && 'active_mobile_nav'}`} id="mobile-nav-div">
                        <div class="flex gap-28 flex-col pl-5 pt-5">
                            <ul class="flex gap-5 flex-col text-paragraph text-base leading-5 font-medium font-inter">
                                <li class="group relative">
                                <NavLink to="/">
                                    <a onClick={()=>opennav(false)} class="home-two-nav-item leading-5 relative before:content-['الرئيسية'] w-fit m-nav-dropdown flex items-center gap-2">
                                        الرئيسية
                                    </a>
                                    </NavLink>
                                </li>
                                <li>
                                <NavLink to="/Project">
                                    <a onClick={()=>opennav(false)} class="home-two-nav-item relative before:content-['المشاريع'] w-fit">
                                        المشاريع
                                    </a>
                                    </NavLink>
                                </li>
                                <li>
                                <NavLink to="/WorkTeam">
                                    <a onClick={()=>opennav(false)} class="home-two-nav-item relative before:content-['فريق العمل'] w-fit">
                                        فريق العمل
                                    </a>
                                    </NavLink>

                                </li>
                                {/* <li class="home-two-nav-item relative before:content-['مركز الأعمال'] w-fit">
                                <NavLink to="/BusinessCenter">
                                    <a onClick={()=>opennav(false)}>مركز الأعمال</a>
                                    </NavLink>

                                </li> */}
                            </ul>
                        </div>
                        <div class="flex gap-5 flex-col pl-5 mt-5">
                            <a href="https://wa.me/+9660582266691/?text=السلام عليكم، وصلت إليكم من خلال موقعكم الإلكتروني، وأريد الاستفسار.." target="_blank">
                                <button class="btn btn-gradient btn-gradient-top fs-8 mt-1 d-inline-flex">
                                    <span>تواصل بنا</span>
                                </button>
                            </a>

                            <div class="col-lg-4 col-md-3 d-flex">
                                <ul class="social-links">
                                    <li>
                                        <a href="mailto:hala@raneen.com.sa" target="_blank"><i class="bi bi-at social-linksicon"></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/raneenorg" target="_blank"><i class="bi bi-linkedin social-linksicon"></i></a>
                                    </li>
                                    <li>
                                        <a href="https://x.com/raneenorg?s=11" target="_blank"><i class="bi bi-twitter-x social-linksicon"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="transition-all duration-300">
                    <div class="w-full h-screen fixed bg-black/80 z-40 -left-full transition-all duration-300 delay-150" id="mobile-nav-div-overlay"></div>
                    <div class="fixed h-screen overflow-y-scroll bg-white w-full top-16 -left-full z-50 transition-all duration-300 delay-0" id="mobile-nav-div">
                        <div class="flex gap-28 flex-col pl-5 pt-5">
                            <ul
                                class="flex gap-5 flex-col text-paragraph text-base leading-5 font-medium font-inter"
                            >
                                <li class="group relative">
                                    <NavLink to='/'
                                        class="home-two-nav-item leading-5 relative before:content-['الرئيسية'] w-fit m-nav-dropdown flex items-center gap-2"
                                    >
                                        الرئيسية
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to='/Project'
                                        class="home-two-nav-item relative before:content-['المشاريع'] w-fit"
                                    >
                                        المشاريع
                                    </NavLink>
                                </li>
                                <li>
                                <NavLink to='/WorkTeam'
                                        class="home-two-nav-item relative before:content-['فريق العمل'] w-fit"
                                    >فريق العمل</NavLink>
                                </li>
                                {/* <li
                                    class="home-two-nav-item relative before:content-['مركز الأعمال'] w-fit"
                                >
                                    <NavLink to='/BusinessCenter'>
                                        مركز الأعمال
                                    </NavLink>
                                </li> */}
                            </ul>
                        </div>
                        <div class="flex gap-5 flex-col pl-5 mt-5">
                            <a target="_blank"
                                href="https://wa.me/+9660582266691/?text=السلام عليكم، وصلت إليكم من خلال موقعكم الإلكتروني، وأريد الاستفسار.."
                            >
                                <button
                                    class="btn btn-gradient btn-gradient-top fs-8 mt-1 d-inline-flex"
                                >
                                    <span>تواصل بنا</span>
                                </button>
                            </a>

                            <div class="col-lg-4 col-md-3 d-flex">
                                <ul class="social-links">
                                    <li>
                                        <a href="mailto:hala@raneen.com.sa" target="_blank"
                                        ><i class="bi bi-at social-linksicon"></i></a>
                                    </li>

                                    <li>
                                        <a href="https://www.linkedin.com/company/raneenorg" target="_blank"
                                        ><i class="bi bi-linkedin social-linksicon"></i></a>
                                    </li>

                                    <li>
                                        <a href="https://x.com/raneenorg?s=11" target="_blank"
                                        ><i class="bi bi-twitter-x social-linksicon"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
}

export default Header;
